export default class changeUnitUtils {
    static getUnitAndVal(value) {
        if( value < 1000){
            return [value.toFixed(2),'kWh'];
          }
          else if(value > 1000 && value < 1000000){
            return [(value/1000).toFixed(2),'MWh'];
          }else if(value > 1000000){
           return [(value / 1000000).toFixed(2),'GWh'];
          }
          else{
            return [value.toFixed(2),'kWh'];
          }
    }
   
}