<template>
  <div style="overflow-y: scroll;">
    <div class="home" id="header-nav">
      <div class="profile">
        <div class="initials">{{ nameInitials ? nameInitials : "👤" }}</div>
        <div class="profile-info">
          <div class="profile-text">{{ userName ? userName : "👤" }}</div>
          <div class="date">{{ date }}</div>
        </div>
      </div>
    </div>
    <div class="carousel-container">
      <Carousel
          ref="carousel"
          :itemsToShow="1"
          :itemsToScroll="1"
          :autoplay="false"
          :loop="false"
          v-model="currentSlide"
      >
        <Slide v-for="(slide, index) in slides" :key="index">
          <div class="slide">
            <div
                class="slide-background"
                :style="{ backgroundImage: 'url(' + slide.image + ')' }"
            ></div>
            <div v-if="index === 0" class="slide-content layout1">
              <div class="slide-text"><img src="../../images/tri-new.svg" alt=""></div>
              <div class="slide-text">Centralize, <span>Optimize,</span> Revolutionize</div>
            </div>
            <div v-else-if="index === 1" class="slide-content layout2">
              <div class="left-content">
                <div class="smoke_image">
                  <img src="../../images/smoke.svg" class="slide-image-left"/>
                </div>
                <div class="slide-text-left">267.29t</div>
              </div>
              <div class="right-content">
                <div class="slide-text-right">Carbon Emission</div>
                <div class="slide-text-right partner">We partner with environmental non-profits and tree- planting
                  organisations globally so you can offset your carbon emissions in your own backyard.
                </div>
              </div>
            </div>
            <div v-else class="slide-content layout3">
              <div class="left-content">
                <div class="smoke_image leaf">
                  <img src="../../images/leaf.svg" class="slide-image-left"/>
                </div>
                <div class="slide-text-left">11,678</div>
              </div>
              <div class="right-content">
                <div class="slide-text-right planting">Planting Green <br> Growing Life.</div>
                <div class="slide-text-right partner"></div>
              </div>
            </div>
          </div>
        </Slide>
      </Carousel>
      <div class="dots">
      <span
          class="dot"
          :class="{ active: currentSlide === index }"
          v-for="(slide, index) in slides"
          :key="index"
          @click="goToSlide(index)"
      ></span>
      </div>
    </div>

    <div style="padding: 0 20px;">
      <div class="power_input">
        <p class="power_intro">Total Power Input</p>
        <p class="power_date">{{ tg === 0 ? 'Yesterday' : date }}</p>
        <div>
          <BarChart :unit="'kWh'" v-if="loaded"/>
          <p v-else>Loading...</p>
        </div>
      </div>
    </div>
    <div style="padding: 15px 20px 0;">
      <p class="status_top">Status</p>
      <div class="status">
        <div class="status-item">
          <p class="units_today">{{ tg ? tg : 0 }} <span v-if="tg">{{ tgUnit ? tgUnit : '' }}</span></p>
          <p class="today">Today</p>
          <div class="border-line"></div>
        </div>
        <div class="status-item">
          <p class="units_today">{{ yg ? yg : 0 }} <span v-if="yg">{{ ygUnit ? ygUnit : '' }}</span></p>
          <p class="today">Yesterday</p>
          <div class="border-line"></div>
        </div>
        <div class="status-item">
          <p class="units_today">{{ atg ? atg : 0 }} <span>{{ totalGenerationUnit }}</span></p>
          <p class="today">{{totalGenerationLabel ? totalGenerationLabel : 'This month'}}</p>
          <div class="border-line"></div>
        </div>
      </div>
      <div class="printing_weather">
        <div class="weather_intro">
          <h2>Your Overall Impact</h2>
          <p>{{ date }}</p>
        </div>

        <div class="weather-card-container">
          <div class="weather-card">
            <div class="weather-card-left">
              <div class="weather-image-container" style="background-color: #00278B;">
                <img src="../../images/clouds1.svg" alt="Image 1"/>
              </div>
            </div>
            <div class="weather-card-right">
              <p class="weather-main-text">{{ temperature ? `${temperature}°` : "N/A"}}</p>
              <p class="weather-sub-text">Weather</p>
            </div>
          </div>
          <div class="weather-card">
            <div class="weather-card-left">
              <div class="weather-image-container" style="background-color: #011CAA;">
                <img src="../../images/solar-weather.svg" alt="Image 4"/>
              </div>
            </div>
            <div class="weather-card-right">
              <p class="weather-main-text">{{ capacity ? capacity :"N/A"}} <span v-if="capacity" style="font-size: 10px">{{ sizeUnit ? sizeUnit : "kW"  }}</span></p>
              <p class="weather-sub-text">Solar</p>
            </div>
          </div>


          <div class="weather-card">
            <div class="weather-card-left">
              <div class="weather-image-container" style="  background: linear-gradient(to bottom, #00F2B2, #013426);">
                <img src="../../images/ruppee.svg" alt="Image 3"/>
              </div>
            </div>
            <div class="weather-card-right">
              <p class="weather-main-text">{{ ats ? ats:"N/A"}}<span v-if="ats">{{ savingSuffix }}</span></p>
              <p class="weather-sub-text">Savings</p>
            </div>
          </div>
          <div class="weather-card">
            <div class="weather-card-left">
              <div class="weather-image-container" style="background-color: #00F2B2;">
                <img src="../../images/weather-ev.svg" alt="Image 2"/>
              </div>
            </div>
            <div class="weather-card-right">
              <p class="weather-main-text">N/A</p>
              <p class="weather-sub-text">EV</p>
            </div>
          </div>

        </div>


      </div>
    </div>
  </div>
</template>


<script>
import {Carousel, Slide} from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import BarChart from '../components/BarChart.vue';
import Slide1 from '../../images/slide_bg1.svg';
import Slide2 from '../../images/slide_bg_2.svg'; 
import Slide3 from '../../images/slide_bg_3.svg'; 
import DataService from '@/services/data.service';
import UserService from '@/services/user.service';
import changeUnitUtils from '@/utils/changeUnitUtils';

export default {
  components: {Carousel, Slide, BarChart},
  data() {
    return {
      slides: [
        {image: Slide1, text1: 'Slide 1 Text 1', text2: 'Slide 1 Text 2'},
        {image: Slide2, text: 'Slide 2 Text'},
        {image: Slide3, text: 'Slide 3 Text'},
      ],
      currentSlide: 0,
      tg: 0,
      yg: 0,
      atg: 0,
      ats: 0,
      savingSuffix: '',
      capacity: 0,
      sizeUnit: 'kW',
      temperature: 0,
      totalGenerationLabel:'This month',
      totalGenerationUnit:'kWh',
      tgUnit: 'kWh',
      ygUnit: 'kWh',
      date: "",
      userName:"App User",
      user:null,
      nameInitials: "",
      loaded:false
    };
  },
  async mounted() {
    try {
      this.user = await UserService.getCurrentUser();
      this.userName = this.user?.name?.str;
        if (this.userName) {
        this.userName= this.userName.split(' ').slice(0, 2).join(' ');   
        const names = this.userName.split(" ");
        this.nameInitials = names[0].charAt(0)
        if (names.length > 1) {
          this.nameInitials += names[names.length - 1].charAt(0)
        }
      }
      const result=await DataService.getData("");
      if(result){
      this.loaded=true;
      [this.tg,this.tgUnit] =changeUnitUtils.getUnitAndVal(result.todayTotal);
      [this.yg,this.ygUnit]= changeUnitUtils.getUnitAndVal(result.yesterdayTotal);
      [this.atg,this.totalGenerationUnit]=changeUnitUtils.getUnitAndVal(result.totalGenerationTracked);
      this.ats =this.getTotalSavings(result.totalSavings);
      this.date = result.dateToday;
      this.temperature=result.temperature;
      this.capacity=this.getSize(result.plantSize);
      this.totalGenerationLabel=result.totalGenerationLabel;
      }
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    goToSlide(index) {
      this.currentSlide = index;
    },

   

    getSize(size){
       if(size<1000){
         this.sizeUnit='kW';
         return size;
         }
       else if(size>1000 && size<1000000){
         this.sizeUnit='MW';
         return (size/1000).toFixed(2);
       }else if(totalGenerationTracked>1000000){
        this.sizeUnit='GW';
        return (size/1000000).toFixed(2);
       }
       else{
        this.sizeUnit='kW';
        return size;
       }
    },
    getTotalSavings(totalSavings){
      if(totalSavings<1000){
        return totalSavings.toFixed(0);
      }else if(totalSavings>1000 && totalSavings<1000000){
        this.savingSuffix='k';
        return (totalSavings/1000).toFixed(2);
      }else if(totalSavings>1000000){
        this.savingSuffix='m';
        return (totalSavings/1000000).toFixed(2);
      }
    }
  },
};
</script>
<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.home {
  padding: 10px;
  overflow-y: scroll;
  position: fixed;
  width: 100%;
  background-color: #ffffff;
  z-index: 999999999;
}

.profile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
}

.initials {
  position: absolute;
  left: 40px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #453A94;
  font-family: "Inter", sans-serif;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
}

.profile-info {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.profile-text {
  font-size: 15px;
  font-weight: 600;
  color: #453a94;
}
.date {
  font-size: 12px;
  color: #575757;
  font-weight: 400;
}

.carousel-container {
  position: relative;
  width: 100%;
  margin-top: 70px;
}

.slide {
  position: relative;
  width: calc(100% - 40px);
  height: 150px;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
}

.slide-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -16px;
  left: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.slide-content {
  position: relative;
  left: 0;
  padding-left: 30px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout1 {
  flex-direction: column;
  align-items: start;
}

.layout2 {
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}

@media (max-width: 1200px) {
  .layout2 {

    justify-content: flex-start;
  }
}

@media (min-width: 1200px) {
  .layout2 {

    justify-content: flex-start;
  }
}

.layout3 {
  flex-direction: row;
  justify-content: start;
  gap: 20px;
  padding: 10px;
}

.left-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.right-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.slide-text {
  position: relative;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  padding: 10px;
  z-index: 1;
}

.slide-text span {
  color: #00f2b2;
}

.slide-text-left {
  font-size: 12px;
  padding: 4px 10px;
  z-index: 1;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
}

.slide-text-right {
  font-size: 20px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  padding: 0 10px;
  z-index: 1;
  text-align: left;
}

.partner {
  font-weight: 500;
  font-size: 10px;
  font-family: 'Montserrat', sans-serif;
  text-align: left;
}

.slide-image {
  margin-top: 10px;
}

.dots {
  display: flex;
  justify-content: center;
}

.dot {
  width: 6px;
  height: 6px;
  background-color: #E4E4E4;
  border-radius: 50%;
  margin: 0 2px;
  cursor: pointer;
}

.dot.active {
  background-color: #00F2B2;
}

.power_input {
  padding: 20px;
  background: linear-gradient(180deg, #F9F9F9 0%, #F2F2F2 100%);
  border-radius: 20px;
  margin-top: 20px;
}

.power_intro {
  font-size: 18px;
  color: #000000;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  text-align: left;
  margin: 0;
}

.power_date {
  font-size: 12px;
  font-weight: 400;
  color: #000;
  font-family: 'Montserrat', sans-serif;
  text-align: left;
}

.smoke_image {
  background-color: #3b5036;
  border-radius: 50%;
  padding: 15px;
}

.leaf {
  padding: 15px 18px;
}

.planting {
  margin-top: -20px;
}

.status {
  display: flex;
  justify-content: space-between;
  padding: 17px 2px;
  background: linear-gradient(to right, #050011, #120B41);
  background: linear-gradient(-42.979deg, #1D0C47 0%, #050011 100%);
  border-radius: 20px;
}

.status_top {
  font-size: 12px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  text-align: left;
  padding: 0 10px;
  margin: 0 0 10px;

}

.status-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 10px 0;
  text-align: center;
  width: calc(100% / 3);
}

.border-line {
  position: absolute;
  width: 1px;
  height: 100%;
  background: linear-gradient(-42.979deg, #7F74D2 100%, #D6D1FF 100%);
  right: 0;
  top: 0;
  transform: translateX(50%);
}

.status-item:last-child .border-line {
  display: none;
}

.units_today {
  display: flex;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  color: #ffffff;
  font-weight: 500;
  margin: 0;
}

.units_today span {
  font-weight: 400;
  line-height: 30px;
  padding-left: 5px;
  font-size: 10px;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
}

.today {
  font-size: 10px;
  color: #ffffff;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
}

.weather_intro {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0 10px;

}

.weather_intro h2 {
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  margin: 0 0 10px;
}

.weather_intro p {
  font-size: 10px;
  font-weight: 400;
  margin: 0 0 10px;
  font-family: 'Montserrat', sans-serif;
}

.weather-card-container {
  gap: 10px;
  columns: 2 6rem;
}

.weather-card-container > div:first-child{
  margin-top: 0;
}
.weather-card {
  display: flex;
  margin-top: 10px;
  width: 100%;
  flex-direction: row;
  text-align: center;
  background-color: #3187D6;
  border-radius: 15px;
  overflow: hidden;
  border: none;
  color: #fff;
}

@media screen and (max-width: 344px) and (max-height: 882px) {

}


@media screen and (max-width: 568px) {

}

@media screen and (min-width: 768px) {

}


@media screen and (min-width: 992px) {

}

@media only screen and (min-width: 600px) and (max-height: 1024px) {

}

.weather-card:nth-child(1) {
  background-color: #3187D6;
}

.weather-card:nth-child(2) {
  background-color: #000000;
}

.weather-card:nth-child(3) {
  background-color: #09CA97;
}

.weather-card:nth-child(4) {
  background-color: #041A6C;
}

.weather-card-left {
  display: flex;
  align-items: center;
  padding:15px 10px 14px 15px;
}

.weather-image-container {
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden;
}

.weather-image-container img {
  max-width: 100%;
  max-height: 100%;
}

.weather-card-right {
  flex: 1;
  padding: 18px 0;
  text-align: left;
}

.weather-main-text {
  font-size: 20px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  margin: 0;
}

.weather-sub-text {
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  color: #ffffff;

}


</style>
