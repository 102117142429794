import {createRouter, createWebHashHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SplashScreen from "../components/SplashScreen"
import Login from "../views/Login.vue"
import Address from "../views/Address.vue"
import Inverter from "../views/Inverter.vue"
import ScanQr from "../views/ScanQr.vue"
import SuccessfullyAdded from "../views/SuccesfullyAdded"
import HomePage from "../views/HomePage.vue"
// import DetailedConsumption from "../views/DetailedConsumption"
import Dashboard from "../views/Dashboard"
// import SitePage from "../views/SitePage"
import BuySell from "../views/BuySell"
import AutoPurchase from '../components/AutoPurchase.vue'
import Home from '../views/Home.vue'
import TransactionComplete from "../views/TransactionComplete.vue"
import Summary from "../views/Summary.vue"
import NewPage from '../components/NewPage'
import ViewWallet from '@/components/ViewWallet.vue'
import AuthService from "@/services/auth.service";
import SitePage from "@/views/SitePage.vue";
import TransactionHistory from '@/components/TransactionHistory.vue'
import Notifications from '@/components/Notifications.vue'

const routes = [
    {
        path: '/',
        name: 'splash',
        component: SplashScreen,
        meta: {requiresAuth: false}
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {requiresAuth: false}
    },
    {
        path: '/address',
        name: 'address',
        component: Address,
        meta: {requiresAuth: true}
    },
    {
        path: '/connect_to_inverter',
        name: 'inverter',
        component: Inverter,
        meta: {requiresAuth: true}
    },
    {
        path: '/scanqr',
        name: 'scanqr',
        component: ScanQr,
        meta: {requiresAuth: true}
    },
    {
        path: '/added',
        name: 'successfullyadded',
        component: SuccessfullyAdded,
        meta: {requiresAuth: true}
    },
    {
        path: '/homepage',
        name: 'homepage',
        component: HomePage,
        meta: {requiresAuth: true}
    },
    // {
    //   path:'/detailedconsumption',
    //   name:'detailedconsumption',
    //   component:DetailedConsumption,
    //         meta: { requiresAuth: true }
    // },
    {
        path: '/sitepage',
        name: 'sitepage',
        component: SitePage,
        meta: {requiresAuth: true}
    },
    {
        path: '/buysell',
        name: 'buysell',
        component: BuySell,
        meta: { requiresAuth: true }
    },
    {
        path: '/autopurchase',
        name: 'autopurchase',
        component: AutoPurchase,
        meta: { requiresAuth: true }
    },
    {
        path: '/home',
        name: 'home',
        component: Home,
        meta: { requiresAuth: true }
    },
    {
        path: '/transaction',
        name: 'transaction',
        component: TransactionComplete,
        meta: { requiresAuth: true }
    },
    {
        path: '/wallet',
        name: 'ViewWallet',
        component: ViewWallet,
        meta: { requiresAuth: true }
    },
    {
        path: '/transactionhistory',
        name: 'TransactionHistory',
        component: TransactionHistory,
        meta: { requiresAuth: true }
    },
    {
        path: '/notifications',
        name: 'Notifications',
        component: Notifications,
        meta: { requiresAuth: true }
    },
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
})

// Asynchronous Navigation Guard
router.beforeEach(async (to, from, next) => {
    const isAuthenticated = AuthService.isLoggedIn();

    if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
        next({name: 'Login'}); // redirect to login page if not authenticated
    } else {
        next(); // allow the route
    }
});

export default router
