<template>
    <div class="notification-page">
      <div class="left" @click="goBack">
        <img src="../../images/back.png" alt="Back Button" class="back-button" />
        <span class="text">Notifications</span>
      </div>
      <transition-group
        name="slide"
        tag="div"
        class="notification-list"
        v-if="notifications.length > 0"
      >
        <div
          v-for="(notification, index) in notifications"
          :key="notification.text"
          class="notification-item"
        >
          <p class="notification-text">{{ notification.text }}</p>
          <button @click="removeNotification(index)" class="remove-btn">
            <img src="../../images/closew.svg" alt="" />
          </button>
        </div>
      </transition-group>
      <p v-else class="no-notifications">No new notifications</p>
    </div>
  </template>
  
  
  <script>
  export default {
    data() {
      return {
        notifications: [
          { text: "You have a new message!" },
          { text: "You have a new message!" },
          { text: "You have a new message!" },
        ],
      };
    },
    methods: {
      removeNotification(index) {
        this.notifications.splice(index, 1);
      },
      goBack() {
      this.$router.go(-1); 
    },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .left {
    display: flex;
    align-items: center;
    .back-button {
      margin-right: 10px;
    }
  
    .text {
      font-size: 18px;
      font-weight: bold;
    }
  }
  
  .notification-page {
    padding: 20px;
    max-width: 600px;
    margin: auto;
    font-family: Arial, sans-serif;
  
    .title {
      text-align: center;
      font-size: 24px;
      margin-bottom: 20px;
    }
  
    .notification-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 5%;
  
      .notification-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #f9f9f9;
        padding: 10px 15px;
        border-radius: 8px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  
        .notification-text {
          margin: 0;
        }
  
        .remove-btn {
          background-color: #453a94;
          color: white;
          border: none;
          border-radius: 50%;
          padding: 5px 5px;
          cursor: pointer;
          font-size: 14px;
          transition: background-color 0.3s ease;
  
          img {
            height: 7px;
            width: 7px;
          }
        }
      }
    }
  
    .no-notifications {
      text-align: center;
      font-size: 18px;
      color: gray;
      margin-top: 5%;
    }
  }
  
  .slide-enter-active,
  .slide-leave-active {
    transition: transform 0.5s ease, opacity 0.5s ease;
  }
  
  .slide-enter {
    transform: translateX(-100%);
    opacity: 0;
  }
  
  .slide-leave-to {
    transform: translateX(-100%);
    opacity: 0;
  }
  </style>
  
  