// store.js
import { createStore } from 'vuex';

const store = createStore({
  state: {
    selectedCard: localStorage.getItem('selectedCard') || '',
    currentUserNodeDetail:null,
  },
  mutations: {
    setSelectedCard(state, card) {
      console.log( card,"selected");
      state.selectedCard = card;
      localStorage.setItem('selectedCard', card);
    },
    setCurrentUserNodeDetail(state, nodeDetail) {
      state.currentUserNodeDetail = nodeDetail;
    }
  },
  actions: {
    updateSelectedCard({ commit }, card) {
      commit('setSelectedCard', card);
    },
    updateCurrentUserNodeDetail({ commit }, nodeDetail) {
      commit('setCurrentUserNodeDetail', nodeDetail);
    }
  },
  getters: {
    selectedCard: (state) => state.selectedCard,
    currentUserNodeDetail: (state) => state.currentUserNodeDetail
  }
});

export default store;
