<template>
    <div class="inverter_status">
    <div class="inverter-card-container">
      <div class="inverter-card">
  <div class="inverter-image-section">
    <img src="../../images/siteinv.png" alt="Inverter Image" />
  </div>
  <div class="inverter-text-section">
    <div class="inverter-text-row">
      <span class="inverter-label">Brand:</span>
      <span class="inverter-value">{{ brand ? brand : "N/A" }}</span>
    </div>
    <div class="inverter-text-row">
      <span class="inverter-label">Model No.:</span>
      <span class="inverter-value">{{ model ? model : "N/A" }}</span>
    </div>
    <div class="inverter-text-row">
      <span class="inverter-label">Size:</span>
      <span class="inverter-value">{{ size ? size : "N/A" }} kW</span>
    </div>
  </div>
</div>

    </div>

    <div>
      <div class="status_container">
        <p class="status_top">Status</p>
        <div class="status">
          <div class="status-item">
            <p class="units_today">{{ tg ? tg : 0 }} <span v-if="tg">{{tgUnit ? tgUnit : ''}}</span></p>
            <p class="today">Today</p>
            <div class="border-line"></div>
          </div>
          <div class="status-item">
            <p class="units_today">{{ yg ? yg : 0 }} <span v-if="yg">{{ygUnit ? ygUnit : ''}}</span></p>
            <p class="today">Yesterday</p>
            <div class="border-line"></div>
          </div>
          <div class="status-item">
            <p class="units_today">{{ atg ? atg : 0 }} <span v-if="atg">{{ totalGenerationUnit ? totalGenerationUnit : '' }}</span></p>
            <p class="today">{{totalGenerationLabel ? totalGenerationLabel : 'This month'}}</p>
            <div class="border-line"></div>
          </div>
        </div>
        </div>
    </div>

<div class="status_chart">
    <h1>Generation </h1>
    <BarChart  v-if="loaded"
    :data="genD"
    :labels="genLabels"/>
    <p v-else>Loading...</p>
</div>


</div>
  </template>
  
  <script>
import BarChart from './BarChart.vue';
import Status from './Status.vue';
import DataService from "@/services/data.service";
import changeUnitUtils from "@/utils/changeUnitUtils";

export default {
    name: "SiteInverters",
    components: {
        BarChart,
        Status
      },
    props: {
      imageSrc: {
        type: String,
      },
      inverterData :{
        type:Object
      },
      startDate:{
        type:String
      },
      endDate:{
        type:String
      },
      range:{
        type:String
      },
    },
    data() {
    return {
      tg: 0,
      tgUnit: 'kWh',
      yg: 0,
      ygUnit: 'kWh',
      atg: 0,
      ats: 0,
      savingSuffix: '',
      capacity: 0,
      totalGenerationLabel:'This month',
      totalGenerationUnit:'kWh',
      loaded:false,
      result: null,
      loaded: false,
      brand:'',
      model:'',
      size:'',
      genLabels:[],
      genD:[],
    };
  },
  async mounted() {
     try {
      this.brand = this.inverterData.brand;
      this.model = this.inverterData.model;
      this.size = this.inverterData.size;
      this.result = await DataService.getDataForDateRange(
         this.startDate,
         this.endDate,
         this.inverterData.imei
      );  
      if(this.result) {
      this.genD=this.result.todayGraph;
      this.genLabels=this.result.genLabels;
      this.totalGenerationLabel=this.result.totalGenerationLabel;
      this.setGenLabelsAndData();
      [this.tg,this.tgUnit] =changeUnitUtils.getUnitAndVal(this.result.todayTotal);
      [this.yg,this.ygUnit]= changeUnitUtils.getUnitAndVal(this.result.yesterdayTotal);
      [this.atg,this.totalGenerationUnit]=changeUnitUtils.getUnitAndVal(this.result.totalGenerationTracked);
      this.loaded = true;
      }
     }catch (error) {
       console.log(error);
     }
      
  },
    methods:{
     
    setGenLabelsAndData(){
      switch (this.range) {
        case "today":
        this.genD = this.result.todayGraph;
        this.genLabels = this.result.genLabels;
        break;
        case "yesterday":
        this.genD = this.result.yesterdayGraph;
        this.genLabels = this.result.genLabels;
        break;
        case "past7":
        this.genD = this.result.loadPastToDTotals;
        this.genLabels = this.result.genLabels;
        break;
        case "mtd":
        this.genD = this.result.loadPastToDTotals;
        this.genLabels = this.result.genLabels;
        break;
        case "month":
        this.genD = this.result.loadPastToDTotals;
        this.genLabels = this.result.genLabels;
        break
        default:
        this.genD = this.result.todayGraph;
        this.genLabels = this.result.genLabels;
        break;
      }
    }
    }
  };
  </script>
  
  <style lang="scss" scoped>
.status_container{
    padding:1rem;
}
.status_top {
  font-size: 14px;
  
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  text-align: left;
  padding: 0 10px;
  margin: 0 0 10px;

}

  h1{
    text-align: left;
    text-transform: uppercase;
    font-size: 14px;
    color: #000;
    font-weight: 600;
    padding: 0rem 0.5rem;
  }
.inverter_status{
    background-color: #f5f5f5;
    border-radius: 15px;
}
  .inverter-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .inverter-card {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
  padding: 1rem;
  border-radius: 8px;
}

.inverter-image-section {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 150px;
}

.inverter-image-section img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}

.inverter-text-section {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.inverter-text-row {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  gap:0.5rem;
  
}

.inverter-label {
  font-weight: 900;
  color: #333;
}

.inverter-value {
  font-weight: 400;
  color: #000;
  font-size: 14px;
}

.status {
  display: flex;
  justify-content: space-between;
  padding: 17px 2px;
  background: linear-gradient(to right, #050011, #120B41);
  background: linear-gradient(-42.979deg, #1D0C47 0%, #050011 100%);
  border-radius: 20px;
}
.status-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 10px 0;
  text-align: center;
  width: calc(100% / 3);
}
.border-line {
  position: absolute;
  width: 1px;
  height: 100%;
  background: linear-gradient(-42.979deg, #7F74D2 100%, #D6D1FF 100%);
  right: 0;
  top: 0;
  transform: translateX(50%);
}
.status-item:last-child .border-line {
  display: none;
}
.units_today {
  display: flex;
  font-size: 20px;
  font-family: "Inter", sans-serif;
  color: #00FFBC;
  font-weight: 500;
  margin: 0;
}
.units_today span {
  font-weight: 400;
  line-height: 30px;
  padding-left: 5px;
  font-size: 10px;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
}
.today {
  font-size: 10px;
  color: #ffffff;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
}
.status_chart{
    padding:0.5rem
}
  </style>




  


 
