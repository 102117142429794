import ApiCoreService from "./api.core.service";
import UserService from "@/services/user.service";

export default class DataService extends ApiCoreService {
  static get token() {
    const token = localStorage.getItem("_token"); // Fetch from localStorage
    return token ? token : null;
  }

  static get userId() {
    const userId = localStorage.getItem("_id"); // Fetch from localStorage
    return userId ? userId : null;
  }

  static async getSiteDetail() {
    let node;
    if (localStorage.getItem("tnode")) {
      node = JSON.parse(localStorage.getItem("tnode"));
    } else {
      const result = await UserService.getCurrentUserNode();
      if (result.length > 0) {
        node = result.shift();
        localStorage.setItem("tnode", JSON.stringify(node));
      }
    }
    if (node) {
      const inverter = node.node.hardwareGroups[0].devices[0];
      return {
        deviceId: node.meterType + " (" + node.no + ")",
        inverter:
          inverter.hardwareType.brand.name.str +
          " (" +
          inverter.hardwareType.model.str +
          ")",
        imei: inverter.uid,
        sim: inverter.id,
      };
    } else
      return {
        deviceId: "-",
        inverter: "-",
        imei: "-",
        sim: "-",
      };
  }

 

  static async getAvailableProducedSoldData() {
    const node = (await UserService.getCurrentUserNode()).shift();
    const sales = (await UserService.getSalesForTheDay()).reduce(
      (p, c) => p + c.units,
      0
    );
    if (node) {
      return {
        available: (node.averageDailyExport - sales).toFixed(2),
        produced: node.averageDailyProduction.toFixed(2),
        sold: sales.toFixed(2),
      };
    } else
      return {
        available: "-",
        produced: "-",
        sold: "-",
      };
  }

  static async getData(imei) {
    try {
      return await this.graphCall(
        "withAuth.getData",
        `{
       withAuth(token: "${this.token}"){
             getData(date:""  imeiNum:"${imei}"){
              genLabels
              todayGraph
              yesterdayGraph
              todayTotal
              yesterdayTotal
              totalGenerationLabel
              totalGenerationTracked
              totalGenerationTrackedFullDays
              totalSavings
              dateToday
              systemOnlineLabel
              systemOnline
              plantSize
              specificYield
              temperature
              currentPower
              holisticGraph{
                    labels
                    totalGen
                    totalCon
                    usedGen
                    exportedGen
                    sy
                  }
              loadPastToDTotals
              plantedTrees
              carbonReduction
        }  
    }
    }`
      );
    } catch (c) {
      throw new Error(c);
    }
  }
 

  static async getDataForDateRange(startDate, endDate,imei) {
    try {
      return await this.graphCall(
        "withAuth.getDataForDateRange",
        `{
       withAuth(token: "${this.token}"){
          getDataForDateRange(startDate:"${startDate}" endDate:"${endDate}" imeiNum:"${imei}"){
          genLabels
          todayGraph
          yesterdayGraph
          todayTotal
          totalGenerationLabel
          yesterdayTotal
          totalGenerationTracked
          totalGenerationTrackedFullDays
          totalSavings
          dateToday
          systemOnlineLabel
          systemOnline
          plantSize
          specificYield
          temperature
          currentPower
          holisticGraph{
            labels
            totalGen
            totalCon
            usedGen
            exportedGen
            sy            
          }
          loadPastToDTotals
          plantedTrees
          carbonReduction
        }  
    }                                    
    }`
      );
    } catch (c) {
      throw new Error(c);
    } 
          }

  static async hasConsumptionDataEnabled() {
    return false;
  }

  static async listAlerts(alertType) {
    try {
      const status={
        RESOLVED:"Resolved",
        PENDING:"Pending",
      }
      const statusToFind = Object.keys(status).filter(key => status[key] === alertType)[0];
      let node;
      let deviceId;
      let hardwareGroupId;
        const result = await UserService.getUserNodeDetail();
        if (result.length > 0) {
          node = result.shift();
        }
      if (node) {
        deviceId = node.hardwareGroups[0].id;
        hardwareGroupId=node.hardwareGroups[0].devices[0].id;
      }

      
      return await this.graphCall(
        "withAuth.listAlert",
        `{
      withAuth(token: "${this.token}"){
      listAlert(criteria: "{\\"status\\": \\"${statusToFind}\\",\\"hardwareGroup\\":\\"${hardwareGroupId}\\"}"){
      from
      startTime
      endtime
      description
      }   
    }                                    
    }`
      );
    } catch (c) {
      throw new Error(c);
    } 
  }
  
 
  // this.$store.dispatch('updateCurrentUserNodeDetail', { id: 1, name: 'Node 1' });

}