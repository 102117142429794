<template>
  <div class="sitepage_content">
    <div>
      <div class="site_container">
        <div class="left" @click="goBack">
          <img
            src="../../images/back.png"
            alt="Back Button"
            class="back-button"
          />
          <span class="text">Site</span>
        </div>
        <div class="right">
          <div class="circle-container">
            <div class="circle orange" @click="toggleNotifications">
              <img src="../../images/notification.svg" alt="Notification" />
            </div>

            <span
              v-if="notificationCount && !showNotifications > 0"
              class="notification-count"
            >
              {{ notificationCount }}
            </span>

            <div v-if="showNotifications" class="notification-text">
              <span v-if="notificationCount > 0">
                You have {{ notificationCount }} new notifications.
              </span>
              <span
                v-else
                @click="goToNotificationPage"
                class="no-notifications"
              >
                No Notifications
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="date_wrapper">
      <div class="system-overview">
        <div class="date-range">
          <div class="date-selector">
            <select
              v-model="selectedDate"
              @change="
                async () =>
                  await handleDateChange(
                    this.changeDateString(this.startDate),
                    this.changeDateString(this.endDate)
                  )
              "
              class="date-select"
            >
              <img src="../../images/buy_down.png" alt="dropdown" />
              <option value="today">Today</option>
              <option value="yesterday">Yesterday</option>
              <option value="past7">Past 7 Days</option>
              <option value="mtd">Month to Date</option>
              <option value="month">Past Month</option>
            </select>
          </div>
          <div class="date-input-wrapper">
            <div class="date-input-container">
              <input
                type="date"
                v-model="startDate"
                class="date-input"
                ref="startDateInput"
                min="2024-09-15"
              />
              <span class="arrow">→</span>
              <input
                type="date"
                v-model="endDate"
                class="date-input"
                ref="endDateInput"
                :min="startDate"
                :max="new Date().toISOString().split('T')[0]"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <p
      v-if="updatedAtInterval"
      style="text-align: left; margin-top: 10px; color: grey; font-size: 12px"
    >
      Last updated {{ updatedAtInterval }}
    </p>

    <div class="production_consumption">
      <div class="consumption_card">
        <h3>Production</h3>
        <p>{{ generation }} <span>kWh</span></p>
        <div class="content-row">
          <div class="item">
            <div class="blue-dot green"></div>
            <img src="../../images/book.svg" alt="" />
            <p>{{ generationSelf }} kWh</p>
          </div>
          <div class="item">
            <div class="blue-dot"></div>
            <img src="../../images/tower.svg" alt="" />
            <p>{{ generationGrid }} kWh</p>
          </div>
        </div>
        <div style="margin-top: 10px">
          <CProgressStacked>
            <CProgress class="custom-progress" :value="generationProgress" />
            <CProgress
              class="custom-progress-blue"
              :value="100 - generationProgress"
            />
          </CProgressStacked>
        </div>
      </div>

      <div v-if="consumptionData" class="consumption_card">
        <h3>Consumption</h3>
        <p>{{ consumption }} <span>kWh</span></p>
        <div class="content-row">
          <div class="item">
            <div class="blue-dot green"></div>
            <img src="../../images/book.svg" alt="" />
            <p>{{ consumptionSelf }} kWh</p>
          </div>
          <div class="item">
            <div class="blue-dot"></div>
            <img src="../../images/tower.svg" alt="" />
            <p>{{ consumptionGrid }} kWh</p>
          </div>
        </div>
        <div style="margin-top: 10px">
          <CProgressStacked>
            <CProgress
              class="custom-progress-light"
              :value="consumptionProgress"
            />
            <CProgress
              class="custom-progress-dark"
              :value="100 - consumptionProgress"
            />
          </CProgressStacked>
        </div>
      </div>
      <div v-else class="consumption_card performance">
        <h3>Performance</h3>
        <img
          src="../../images/rectangle.png"
          alt="Top Right Image"
          class="top-right-image"
        />

        <p>
          {{ specificYield }}
          <span
            v-if="specificYield >= 3.5"
            style="color: lawngreen; font-size: 1rem"
            >(GOOD)</span
          >
          <span
            v-if="specificYield >= 3 && specificYield < 3.5"
            style="color: orange; font-size: 1rem"
            >(OK)</span
          >
          <span v-if="specificYield < 3" style="color: red; font-size: 1rem"
            >(BAD)</span
          >
        </p>
        <p style="margin-top: 26px; font-size: 12px">
          Specific Yield (kWh/kWp)
        </p>
      </div>
      <div class="consumption_card environment" v-if="!isMultiInverter">
        <img
          src="../../images/green_env1.png"
          alt=" "
          class="env_right_image"
        />
        <div class="environment_circle">
          <div class="env_circle">
            <img src="../../images/tree_env.svg" alt="" />
          </div>
          <div class="env_content" >
            Planted <br />
            trees
            <br />
            <p style="font-size: 16px">
              {{ plantedTrees ? plantedTrees : 10 }}
            </p>
          </div>
        </div>
      </div>

      <div class="consumption_card environment impact" v-if="!isMultiInverter">
        <img
          src="../../images/orange_env1.png"
          alt=""
          class="env_right_image"
        />

        <div class="environment_circle">
          <div class="env_circle_second">
            <img src="../../images/co2.svg" alt="" />
          </div>
          <div class="env_content">
            Carbon <br />
            Reduction
            <br />
            <p style="font-size: 16px">
              {{ carbonReduction ? carbonReduction : 5 }} kg
            </p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="consumptionData" class="performance_yield">
      <h4>Performance</h4>
      <img
        src="../../images/rectangle.png"
        alt="Top Right Image"
        class="top-right-image"
      />
      <div style="text-align: right">
        <p>
          {{ specificYield }}
          <span
            v-if="specificYield >= 3.5"
            style="color: lawngreen; font-size: 1rem"
            >(GOOD)</span
          >
          <span
            v-if="specificYield >= 3 && specificYield < 3.5"
            style="color: orange; font-size: 1rem"
            >(OK)</span
          >
          <span v-if="specificYield < 3" style="color: red; font-size: 1rem"
            >(BAD)</span
          >
        </p>
        <p>Specific Yield (kWh/kWp)</p>
      </div>
    </div>

    <div v-if="isMultiInverter">

      <Status/>
    </div>

    <div class="power_flow_container" v-if="!isMultiInverter">
      <div class="power_flow_head">
        <h2>Power Flow</h2>
        <div class="green_power_dot">
          <p>Live</p>
        </div>
      </div>

      <div class="circle-container">
        <div class="circle">
          <img
            src="../../images/cloud-s.svg"
            alt="Circular Image"
            class="circle-image"
          />
        </div>
        <p class="circle-description">{{ temperature }}°C</p>
      </div>

      <div class="power-column-container">
        <div class="solar_column">
          <img src="../../images/pwer-solar.svg" alt="" />
          <div class="power_text_first">{{ currentPower }} kW</div>
        </div>

        <div class="circle-second-column">
          <div class="direction">
            <img src="../../images/green-s.svg" alt="" />
          </div>
          <div class="direction_two">
            <img src="../../images/orange.svg" alt="" />
          </div>
          <div class="direction_three">
            <img src="../../images/purple-s.svg" alt="" />
          </div>
          <div class="column circle-stack">
            <div class="outer-circle">
              <div class="middle-circle">
                <div class="inner-circle">
                  <img
                    src="../../images/home-s.svg"
                    alt="Inner Circle Image"
                    class="circle-image"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="power_third-column">
          <div class="power_text">No EV</div>
          <div class="power_text">{{ currentPowerSelf }} kW</div>
          <div class="power_text">{{ currentPowerGrid }} kW</div>
        </div>

        <div class="power_fourth-column">
          <div class="solar_column-first" @click="showEvModal = true">
            <img src="../../images/car.svg" alt="" />
          </div>

          <div class="solar_column-fourth">
            <img src="../../images/book-s.svg" alt="" />
          </div>
          <div class="solar_column-fourth">
            <img src="../../images/tower-s.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <NewEv v-if="showEvModal" @close="showEvModal = false" />

    <div style="margin-top: 20px">
      <div class="site_power">
        <div>
          <p>Site Power</p>
        </div>

        <div class="power_energy">
          <!-- <div class="slider"></div> -->
          <!-- <button
            class="button"
            @click="setActive('energy')"
            :class="{ active: activeButton === 'power' }"
          >
            Power
          </button> -->
          <!-- <button
            class="button"
            style="color: #777"
            @click="setActive('energy')"
            :class="{ active: activeButton === 'energy' }"
          >
            Energy
          </button> -->

          <button
          class="button active"
          style="color: #777"
        >
          Energy
        </button>
        </div>
      </div>
    </div>
    <p style="text-align: left; margin-top: 10px; color: grey; font-size: 12px">
      Last Updated {{ updatedAtInterval }}
    </p>

    <div class="graphs-container">
      <div class="graph-cards-row">
        <div class="graph-card productionGraph">
          <h3>Production</h3>
          <div class="content">
            <div class="info">
              <div class="dot green"></div>
              <img src="../../images/tower.svg" alt="Production Image" />
              <p>To Grid</p>
            </div>
            <div class="info">
              <div class="dot blue"></div>
              <img src="../../images/date.svg" alt="Production Image" />
              <p>To Load</p>
            </div>
          </div>
        </div>
        <div v-if="consumptionData" class="graph-card consumptionGraph">
          <h3>Consumption</h3>
          <div class="content">
            <div class="info">
              <div class="dot green"></div>
              <img src="../../images/date.svg" alt="Consumption Image" />
              <p>From Grid</p>
            </div>
            <div class="info">
              <div class="dot blue"></div>
              <img src="../../images/tower.svg" alt="Consumption Image" />
              <p>From Generated</p>
            </div>
          </div>
        </div>
      </div>
      <div class="chart-container">
        <AreaChart v-if="loaded" :startDate="startDate" :endDate="endDate" />
      </div>
    </div>

    <div class="comparative_energy"  >
      <p>Generation</p>
    </div>
    <div>
      <BarChart
        v-if="loaded"
        :range="selectedDate"
        :data="genD"
        :labels="genLabels"
        :unit="'kWh'"
      />
      <p v-else>Loading...</p>
      <br />
    </div>
    <div class="comparative_energy">
      <p>Site Performance</p>
    </div>
    <div class="site-performance">
      <div class="axis-card-row">
        <div class="graph-card">
          <div class="content">
            <div class="info">
              <div class="dot green"></div>
              <img src="../../images/info.svg" alt="Production Image" />
              <p>Solar energy output per kW over time</p>
            </div>
          </div>
        </div>
      </div>
      <div class="chart-container">
        <BarChart
          v-if="loaded"
          :range="selectedDate"
          :labels="perfChartData.labels"
          :data="perfChartData.sy"
          :colours="
            perfChartData.sy.map(
              (val) =>
                `rgba(${
                  !(val >= 3 && val < 3.5)
                    ? Math.floor(255 * (1 - (val - 0) / (5 - 0)))
                    : 255
                }, ${Math.floor(255 * ((val - 0) / (5 - 0)))}, 0, 1)`
            )
          "
        />
        <p v-else>Loading...</p>
        <br />
      </div>
    </div>
<div v-if="!isMultiInverter">
    <div class="comparative_energy">
      <p>Comparative Energy</p>
      <div class="comparative_buttons">
        <div class="slider"></div>
        <button
          class="button"
          @click="setComparative('month')"
          :class="{ active: comparativeButton === 'month' }"
        >
          Month
        </button>
        <button
          class="button"
          @click="setComparative('quarter')"
          :class="{ active: comparativeButton === 'quarter' }"
        >
          Quarter
        </button>
        <button
          class="button"
          @click="setComparative('year')"
          :class="{ active: comparativeButton === 'year' }"
        >
          Year
        </button>
      </div>
    </div>
    <div>
      <br />Not Enough Data to Plot. <br />
      Minimum 1 Month Data Required.
    </div>
    <div
      style="
        background-color: #f8f8f8;
        border-radius: 15px;
        padding: 20px;
        margin-top: 10px;
      "
    >
      <div v-if="loaded" style="margin-bottom: 20px">
        <div class="year_dots">
          <div class="dot_year_pair">
            <span class="dot" style="background-color: #6ec5d6"></span>
            <span class="year">2021</span>
          </div>
          <div class="dot_year_pair">
            <span class="dot" style="background-color: #019fe0"></span>
            <span class="year">2022</span>
          </div>
          <div class="dot_year_pair">
            <span class="dot" style="background-color: #004b84"></span>
            <span class="year">2023</span>
          </div>
          <div class="dot_year_pair">
            <span class="dot" style="background-color: #004b84"></span>
            <span class="year">2024</span>
          </div>
        </div>
      </div>
      <p v-else>Loading...</p>
      <div>
        <ComparitiveEnergyGraph v-if="loaded" />
      </div>
    </div>
  </div>
  </div>
  <div></div>
  <h1 class="site_inverters" v-if="isMultiInverter">Site Inverters</h1>

  <div class="inverter_list" v-for="inverter in inverters" v-if="isMultiInverter">
    <SiteInverters :inverterData="inverter" :startDate="changeDateString(startDate)" :endDate="changeDateString(endDate)"  :range="selectedDate"/>
   </div>
</template>

<script>
import AreaChart from "@/components/AreaChart.vue";
import BarChart from "@/components/BarChart.vue";
import ComparitiveEnergyGraph from "@/components/ComparitiveEnergyGraph.vue";
import DataService from "@/services/data.service";
import UserService from "@/services/user.service";
import NewEv from "@/components/NewEv.vue";
import SiteInverters from "@/components/SiteInverters.vue";
import { formatDistanceToNow } from "date-fns";
import Status from "@/components/Status.vue";

export default {
  data() {
    return {
      showNotifications: false,
      result: null,
      lastUpdated: null,
      selectedDate: "today",
      consumptionData: false,
      showEvModal: false,
      currentView: "Home",
      notificationCount: 0,
      showNotifications: false,
      startDate: new Date().toISOString().slice(0, 10),
      endDate: new Date().toISOString().slice(0, 10),
      startDateInput: null,
      endDateInput: null,
      activeButton: "energy",
      comparativeButton: "month",
      consumption: "N/A",
      consumptionSelf: "00.00",
      consumptionGrid: "00.00",
      consumptionProgress: 60,
      generation: 0,
      generationSelf: 0,
      generationGrid: 0,
      generationProgress: 0,
      specificYield: 0,
      temperature: 0,
      currentPower: 0,
      currentPowerSelf: 0,
      currentPowerGrid: 0,
      perfChartData: {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        sy: [],
      },
      loaded: false,
      genD: [],
      genLabels: [],
      updatedAt: "",
      updatedAtInterval: "",
      plantedTrees: 10,
      carbonReduction: 5,
      isMultiInverter: false,
      inverters: [],
    };
  },
  components: {
    AreaChart,
    ComparitiveEnergyGraph,
    BarChart,
    NewEv,
    SiteInverters,
    Status
  },

  async mounted() {
    try {
      this.consumptionData = await DataService.hasConsumptionDataEnabled();
      await this.checkIsMultiInverter();
      this.result = await DataService.getDataForDateRange(
      this.changeDateString(this.startDate),
      this.changeDateString(this.endDate),
      ''
      );
      this.updatedAt = +new Date(); // Update the last updated timestamp after fetching data
      this.timeAgo();
      if (this.result) {
        this.specificYield = this.result.specificYield.toFixed(2);
        this.temperature = this.result.temperature;
        this.perfChartData = this.result.holisticGraph;
        this.plantedTrees = this.result.plantedTrees;
        this.carbonReduction = this.result.carbonReduction;
        await this.handleDateChange();
        this.pow();
        this.pTF();
        this.loaded = true;
      }
    } catch (error) {
      console.log(error);
    }
  },
  props: ["goBack"],
  methods: {
    async pow() {
      if (this.result.currentPower) {
        this.currentPower = this.result.currentPower.toFixed(2);
        if (this.currentPower > 0)
          this.currentPower =
            ~~this.currentPower + ~~(Math.random() * 100) / 100;
        this.currentPower = this.currentPower.toFixed(2);
        this.currentPowerSelf = (0.8 * this.currentPower).toFixed(2);
        this.currentPowerGrid = (0.2 * this.currentPower).toFixed(2);
        setTimeout(() => this.pow(), 5000);
      } else {
        this.currentPowerSelf = 0;
        this.currentPowerGrid = 0;
      }
    },
    timeAgo() {
      const result = formatDistanceToNow(this.updatedAt, { addSuffix: true });
      this.updatedAtInterval = result;
      setTimeout(() => this.timeAgo(), 60000);
      return this.updatedAtInterval;
    },
    changeDateString(date) {
      return date.split("-").reverse().join("");
    },
    async handleDateChange() {
      let start;
      let end;
      switch (this.selectedDate) {
        case "today":
          try {
            // Set both startDate and endDate to today's date
            this.loaded = false;
            this.startDate = new Date().toISOString().slice(0, 10);
            this.endDate = new Date().toISOString().slice(0, 10);
            start = this.startDate.split("-").reverse().join("");
            end = this.endDate.split("-").reverse().join("");
            this.result = await DataService.getDataForDateRange(start, end,'');
            if (this.result) {
              this.specificYield = this.result.specificYield.toFixed(2);
              this.temperature = this.result.temperature;
              this.perfChartData = this.result.holisticGraph;
              this.plantedTrees = this.result.plantedTrees;
              this.carbonReduction = this.result.carbonReduction;
              this.generation = this.result.todayTotal.toFixed(2);
              this.genD = this.result.todayGraph;
              this.genLabels = this.result.genLabels;
              this.loaded = true;
            }
            break;
          } catch (error) {
            console.log(error);
          }
        case "yesterday":
          try {
            // Set startDate and endDate to yesterday's date
            // Set startDate and endDate to yesterday's date
            this.loaded = false;
            const yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            this.startDate = yesterday.toISOString().slice(0, 10);
            this.endDate = yesterday.toISOString().slice(0, 10);
            start = this.startDate.split("-").reverse().join("");
            end = this.endDate.split("-").reverse().join("");
            this.result = await DataService.getDataForDateRange(start, end,'');
            if (this.result) {
              this.specificYield = this.result.specificYield.toFixed(2);
              this.temperature = this.result.temperature;
              this.perfChartData = this.result.holisticGraph;
              this.plantedTrees = this.result.plantedTrees;
              this.carbonReduction = this.result.carbonReduction;
              this.generation = this.result.yesterdayTotal.toFixed(2);
              this.genD = this.result.yesterdayGraph;
              this.genLabels = this.result.genLabels;
              this.loaded = true;
            }
            break;
          } catch (error) {
            console.log(error);
          }
        case "past7":
          try {
            // Set startDate to 7 days ago and endDate to today
            // Set startDate to 7 days ago and endDate to today
            this.loaded = false;
            const past7 = new Date();
            this.startDate = new Date(past7.setDate(past7.getDate() - 7))
              .toISOString()
              .slice(0, 10);
            this.endDate = new Date().toISOString().slice(0, 10); // today's date
            start = this.startDate.split("-").reverse().join("");
            end = this.endDate.split("-").reverse().join("");
            this.result = await DataService.getDataForDateRange(start, end,'');
            if (this.result) {
              this.specificYield = this.result.specificYield.toFixed(2);
              this.temperature = this.result.temperature;
              this.perfChartData = this.result.holisticGraph;
              this.plantedTrees = this.result.plantedTrees;
              this.carbonReduction = this.result.carbonReduction;
              this.generation = this.result.totalGenerationTracked.toFixed(2);
              this.genD = this.result.loadPastToDTotals;
              this.genLabels = this.result.genLabels;
              this.loaded = true;
            }
            break;
          } catch (error) {
            console.log(error);
          }
        case "mtd":
          try {
            // Set startDate to the first day of the current month and endDate to today
            // Set startDate to the first day of the current month and endDate to today
            this.loaded = false;
            const now = new Date();
            this.startDate = new Date(now.getFullYear(), now.getMonth(), 1)
              .toISOString()
              .slice(0, 10);
            this.endDate = new Date().toISOString().slice(0, 10); // today's date
            start = this.startDate.split("-").reverse().join("");
            end = this.endDate.split("-").reverse().join("");
            this.result = await DataService.getDataForDateRange(start, end,'');
            console.log(this.result, "mtd");
            if (this.result) {
              this.specificYield = this.result.specificYield.toFixed(2);
              this.temperature = this.result.temperature;
              this.perfChartData = this.result.holisticGraph;
              this.plantedTrees = this.result.plantedTrees;
              this.carbonReduction = this.result.carbonReduction;
              this.generation = this.result.totalGenerationTracked.toFixed(2);
              this.genD = this.result.loadPastToDTotals;
              this.genLabels = this.result.genLabels;
              this.loaded = true;
            }
            break;
          } catch (error) {
            console.log(error);
          }
        case "month":
          try {
            // Set startDate to the first day of the previous month and endDate to the last day of the previous month
            // Set startDate to the first day of the previous month and endDate to the last day of the previous month
            this.loaded = false;
            const currentDate = new Date();
            const firstDayOfLastMonth = new Date(
              currentDate.getFullYear(),
              currentDate.getMonth() - 1,
              1
            );
            const lastDayOfLastMonth = new Date(
              currentDate.getFullYear(),
              currentDate.getMonth(),
              0
            );
            this.startDate = firstDayOfLastMonth.toISOString().slice(0, 10);
            this.endDate = lastDayOfLastMonth.toISOString().slice(0, 10);
            start = this.startDate.split("-").reverse().join("");
            end = this.endDate.split("-").reverse().join("");
            this.result = await DataService.getDataForDateRange(start, end,'');
            if (this.result) {
              this.specificYield = this.result.specificYield.toFixed(2);
              this.temperature = this.result.temperature;
              this.perfChartData = this.result.holisticGraph;
              this.plantedTrees = this.result.plantedTrees;
              this.carbonReduction = this.result.carbonReduction;
              this.generation = this.result.totalGenerationTracked.toFixed(2);
              this.genD = this.result.loadPastToDTotals;
              this.genLabels = this.result.genLabels;
              this.loaded = true;
            }
            break;
          } catch (error) {
            console.log(error);
          }
        default:
          try {
            this.loaded = false;
            this.startDate = new Date().toISOString().slice(0, 10);
            this.endDate = new Date().toISOString().slice(0, 10);
            start = this.startDate.split("-").reverse().join("");
            end = this.endDate.split("-").reverse().join("");
            this.result = await DataService.getDataForDateRange(start, end,'');
            if (this.result) {
              this.specificYield = this.result.specificYield.toFixed(2);
              this.temperature = this.result.temperature;
              this.perfChartData = this.result.holisticGraph;
              this.plantedTrees = this.result.plantedTrees;
              this.carbonReduction = this.result.carbonReduction;
              this.generation = this.result.todayTotal.toFixed(2);
              this.genD = this.result.todayGraph;
              this.genLabels = this.result.genLabels;
              this.loaded = true;
            }
          } catch (error) {
            console.log(error);
          }
      }
      this.generationSelf = (this.generation * 1).toFixed(2);
      this.generationGrid = (this.generation * 0).toFixed(2);
      this.generationProgress = 100;
    },

    async checkIsMultiInverter() {
      const data = await UserService.getUserNodeDetail();
      if (data.length > 0 && data[0].hardwareGroups.length > 0) {
        this.isMultiInverter =data[0].hardwareGroups[0].devices.length > 1 ? true :false;
        data[0].hardwareGroups[0].devices.forEach((device) => {
          const imei = device.props.find((el) => el.prop === "imei")?.val || "";
          const slaveId =device.props.find((el) => el.prop === "slaveId")?.val || "";
          const invData={
            brand:device.hardwareType?.brand?.name?.str,
            model:device.hardwareType?.model?.str,
            size:device?.hardwareType?.props.find((el) => el.prop === "size")?.val || "N/A",
            imei:`${imei}-${slaveId}`,
          }
          this.inverters.push(invData);
        });
      }
    },

    openCalendar() {
      if (this.startDateInput) {
        this.startDateInput.focus();
      }
      if (this.endDateInput) {
        this.endDateInput.focus();
      }
    },
    toggleNotifications() {
      this.showNotifications = !this.showNotifications;
    },
    // goToNotificationPage() {
    //   this.$router.push({ name: "Notifications" });
    // },
    updateNotifications(count) {
      this.notificationCount = count;
    },
    setActive(button) {
      this.activeButton = button;
      const slider = document.querySelector(".power_energy .slider");
      const buttonWidth = document.querySelector(
        ".power_energy .button"
      ).offsetWidth;
      if (button === "power") {
        slider.style.transform = `translateX(0)`;
      } else {
        slider.style.transform = `translateX(${buttonWidth}px)`;
      }
    },
    setComparative(button) {
      this.comparativeButton = button;
      const slider = document.querySelector(".comparative_buttons .slider");
      const buttonWidth = document.querySelector(
        ".comparative_buttons .button"
      ).offsetWidth;
      const buttons = ["month", "quarter", "year"];
      const index = buttons.indexOf(button);
      slider.style.transform = `translateX(${index * buttonWidth}px)`;
    },
    pTF() {
      this.consumptionProgress = ~~(Math.random() * 100);
      setTimeout(() => {
        this.pTF();
      }, 5500);
    },
  },
};
</script>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

.site_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  padding: 10px 20px;
  z-index: 999;

  .left {
    display: flex;
    align-items: center;
    cursor: pointer;
    .back-button {
      margin-right: 10px;
    }

    .text {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .right {
    display: flex;
    align-items: center;
    position: relative;

    .circle-container {
      position: relative;

      .circle {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #453a94;
        cursor: pointer;

        &.orange {
          height: 25px;
          width: 25px;
        }

        img {
          width: 18px;
          height: 18px;
        }
      }

      .notification-count {
        position: absolute;
        top: -5px;
        right: -5px;
        background-color: red;
        color: #fff;
        font-size: 10px;
        font-weight: bold;
        border-radius: 50%;
        width: 15px;
        height: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      }

      .notification-text {
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 3px 10px;
        font-size: 12px;
        color: #000000;
        margin-left: 10px;
      }
    }
  }
}

.date-range {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 60px;
}

.date-selector {
  margin-right: 10px;
}

.date-select {
  padding: 3px;
  font-size: 12px;
  color: #000000;
  height: 32px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.date-input-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.date-input-container {
  display: flex;
  align-items: center;
}

.date-input {
  width: 90px;
  height: 32px;
  font-size: 12px;
  padding: 0 8px;
  box-sizing: border-box;
  text-align: center;
  line-height: 32px;
  color: #000000;
  background-color: transparent;
  border: none;
  outline: none;
}

.arrow {
  font-size: 18px;
}

.calendar-icon {
  margin-right: 5px;
}

.production_consumption {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 20px;
  text-align: left;
  gap: 15px;

  .consumption_card {
    background-color: #f8f8f8;
    padding: 12px 10px;
    border-radius: 15px;
    width: 191px;
    @media (max-width: 768px) {
      width: 100%;
    }
    @media (max-width: 992px) {
      width: 100%;
    }

    h3 {
      font-size: 12px;
      color: #453a94;
      font-weight: 700;
      margin-bottom: 10px;
    }

    p {
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 5px;

      span {
        font-size: 10px;
        font-weight: normal;
        color: #6d6d6d;
      }
    }

    .content-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .item {
        display: flex;
        align-items: center;
        gap: 2px;

        .blue-dot {
          width: 4px;
          height: 4px;
          background-color: #0b335b;
          border-radius: 50%;
          margin-right: 2px;
        }

        .green {
          width: 4px;
          height: 4px;
          background-color: #00f2b2;
          border-radius: 50%;
        }

        p {
          margin: 0 0 -3px;
          font-size: 10px;
          font-weight: 400;
          color: #000000;
        }
      }
    }
  }
}

.performance {
  background: linear-gradient(to right, #050011, #120b41);
  color: #ffffff;
  position: relative;
  z-index: 123;

  h3 {
    color: #ffffff !important;
  }
  .top-right-image {
    position: absolute;
    top: 0;
    right: 0px;
    width: 60%;
    height: auto;
    border-radius: 20px;
    z-index: -2;
    @media screen and (width: 600px) and (height: 1024px) {
      width: 30%;
    }
    @media screen and (width: 820px) and (height: 1180px) {
      width: 20%;
    }
    @media screen and (width: 768px) and (height: 1024px) {
      width: 20%;
    }
    @media screen and (width: 1024px) and (height: 1366px) {
      width: 15%;
    }
  }
}

.performance_yield {
  display: flex;
  justify-content: space-between;
  background: linear-gradient(to right, #050011, #120b41);
  border-radius: 15px;
  color: #ffffff;
  padding: 21px;
  margin: 0;
  position: relative;
  overflow: visible;
  z-index: 123;
  margin-top: 20px;

  h4 {
    font-size: 14px;
    font-family: "Montserrat";
    font-weight: 600;
  }

  p:nth-of-type(1) {
    font-size: 20px;
    font-family: "Montserrat";
    font-weight: 600;
    margin: 0;
  }

  p:nth-of-type(2) {
    font-size: 12px;
    font-family: "Montserrat";
  }

  .top-right-image {
    position: absolute;
    top: 0;
    right: 0px;
    width: 40%;
    height: auto;
    border-radius: 20px;
    z-index: -2;
    @media screen and (width: 600px) and (height: 1024px) {
      width: 30%;
    }
    @media screen and (width: 820px) and (height: 1180px) {
      width: 20%;
    }
    @media screen and (width: 768px) and (height: 1024px) {
      width: 20%;
    }
    @media screen and (width: 1024px) and (height: 1366px) {
      width: 15%;
    }
  }
}

.site_power {
  display: flex;
  justify-content: space-between;
  align-items: center;

  p:nth-of-type(1) {
    text-align: left;
    font-size: 14px;
    font-family: "Montserrat";
    color: #000000;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
  }

  p:nth-of-type(2) {
    text-align: left;
    font-size: 10px;
    font-family: "Montserrat";
    color: #aaaaaa;
    margin: 0;
  }
}
//original width: 150px;to do to change
.power_energy {
  position: relative;
  display: flex;
  align-items: center;
  height: 23px;
  border: 1px solid #453a94;
  border-radius: 20px;
  overflow: hidden;
  width: 75px; //todo to change
  background-color: #453a94;


  .slider {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 50%;
    background-color: #453a94;
    transition: transform 0.3s ease;
    border-radius: 20px;
  }

  .button {
    flex: 1;
    border: none;
    background: none;
    color: #000000;
    font-size: 10px;
    font-family: "Montserrat";
    font-weight: 700;
    cursor: pointer;
    z-index: 1;
    text-align: center;
    line-height: 23px;

    &.active {
      color: #ffffff;
    }
  }
}
.site-performance {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 12px;
  background: #f4f4f4;
  padding: 15px;
  border-radius: 15px;
  .axis-card-row {
    display: flex;
    gap: 15px;
  }
  .graph-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 8px 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

    h3 {
      font-size: 10px;
      font-family: "Montserrat";
      color: #000;
      font-weight: 700;
      margin-bottom: 10px;
      text-align: left;
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .info {
        display: flex;
        align-items: center;
        gap: 10px;

        .dot {
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: #0b335b;
        }

        .blue {
          width: 5px;
          height: 5px;
          background-color: #00f2b2;
          border-radius: 50%;
        }

        p {
          font-size: 10px;
          font-family: "Montserrat";
          color: #000;
          margin: 0;
        }
      }
    }
  }
}

.graphs-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 20px;
  background: #f4f4f4;
  padding: 15px;
  border-radius: 15px;

  .graph-cards-row {
    display: flex;
    gap: 15px;
  }

  .graph-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 8px 15px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

    h3 {
      font-size: 10px;
      font-family: "Montserrat";
      color: #000;
      font-weight: 700;
      margin-bottom: 10px;
      text-align: left;
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .info {
        display: flex;
        align-items: center;
        gap: 10px;

        .dot {
          width: 5px;
          height: 5px;
          border-radius: 50%;
          background-color: #0b335b;
        }

        .blue {
          width: 5px;
          height: 5px;
          background-color: #00f2b2;
          border-radius: 50%;
        }

        p {
          font-size: 10px;
          font-family: "Montserrat";
          color: #000;
          margin: 0;
        }
      }
    }
  }
}

.comparative_energy {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;

  p {
    font-size: 14px;
    font-family: "Montserrat";
    color: #000000;
    font-weight: 700;
    text-transform: uppercase;
  }

  .comparative_buttons {
    position: relative;
    display: flex;
    align-items: center;
    height: 23px;
    border: 1px solid #453a94;
    border-radius: 20px;
    overflow: hidden;

    .slider {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 37.33%;
      background-color: #453a94;
      transition: transform 0.3s ease;
      border-radius: 20px;
    }

    .button {
      border: none;
      background: none;
      color: #000000;
      font-size: 10px;
      font-family: "Montserrat";

      z-index: 1;

      &.active {
        font-weight: 500;
        color: #ffffff;
      }
    }
  }
}

.year_dots {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  width: 70%;
  border-radius: 7px;
  padding: 10px;

  .dot_year_pair {
    display: flex;
    align-items: center;
    margin-right: 15px;

    .dot {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 5px;
    }

    .year {
      font-size: 10px;
      font-family: "Montserrat";
    }
  }
}

.custom-progress {
  --cui-progress-bar-bg: #00f2b2;
}

.custom-progress-blue {
  --cui-progress-bar-bg: #0b335b;
}

.custom-progress-light {
  --cui-progress-bar-bg: #00a8bf;
  transition: width 1.5s ease-in-out;
}

.custom-progress-dark {
  --cui-progress-bar-bg: #9dbffd;
  transition: width 1.5s ease-in-out;
}

.power_flow_container {
  background: linear-gradient(to right, #050011, #120b41);
  border-radius: 15px;
  padding: 0px 0px 12px 0px;
  margin-top: 15px;
}

.power_flow_head {
  display: flex;
  justify-content: space-between;
  padding: 20px;

  h2 {
    font-size: 18px;
    font-family: "Montserrat";
    color: #ffffff;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.green_power_dot {
  display: flex;
  align-items: center;

  p {
    margin: 0;
    font-size: 12px;
    font-family: "Montserrat";
    color: #ffffff;
  }

  &::before {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    background-color: #00f2b2;
    border-radius: 50%;
    margin-right: 5px;
  }
}

.circle-container {
  display: flex;
  align-items: center;
  margin-left: 15px;

  .circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #453a94;

    img {
      height: 20px;
      width: 20px;
    }
  }

  .circle-description {
    margin: 0 10px;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
  }
}

.power_flow_container {
  background: linear-gradient(to right, #050011, #120b41);
  border-radius: 15px;
  padding: 0px 0px 12px 0px;
  margin-top: 15px;
}

.power_flow_head {
  display: flex;
  justify-content: space-between;
  padding: 20px;

  h2 {
    font-size: 18px;
    font-family: "Montserrat";
    color: #ffffff;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.green_power_dot {
  display: flex;
  align-items: center;

  p {
    margin: 0;
    font-size: 12px;
    font-family: "Montserrat";
    color: #ffffff;
  }

  &::before {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    background-color: #00f2b2;
    border-radius: 50%;
    margin-right: 5px;
  }
}

.circle-container {
  display: flex;
  align-items: center;
  margin-left: 15px;

  .circle {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #453a94;

    img {
      height: 20px;
      width: 20px;
    }
  }

  .circle-description {
    margin: 0 10px;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
  }
}

.power-column-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  border-radius: 5px;
  height: 150px;
  z-index: 0;
  @media screen and (width: 600px) and (height: 1024px) {
    width: 70%;
  }

  .column {
    color: #ffffff;
    text-align: center;
  }

  .solar_column {
    background: linear-gradient(to bottom, #0578ff, #48b4f1);
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;

    margin: 0 auto;
    align-self: center;
    position: relative;
  }

  .solar_column::after {
    content: "";
    content: "";
    position: absolute;
    width: 33px;
    left: 40px;
    height: 2px;
    background: repeating-linear-gradient(
      to right,
      #00f2b2,
      #00f2b2 5px,
      transparent 5px,
      transparent 10px
    );
    animation: glow-dash-21e921b0 3s linear infinite;
  }

  @keyframes glow-dash {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 20px 0;
    }
  }
}

.circle-second-column {
  display: flex;
  padding: 0px 3px;
  position: relative;
  z-index: 2;
}

.direction {
  position: absolute;
  top: 9px;
  left: 82%;
  z-index: -1;

  @media screen and (width: 384px) and (height: 640px) {
    left: 78%;
  }

  @media screen and (width: 360px) and (height: 740px) {
    left: 74%;
  }
}

.direction_two {
  position: absolute;
  top: 37%;
  left: 88%;
  z-index: -1;
  @media screen and (width: 384px) and (height: 640px) {
    left: 82%;
  }
  @media screen and (width: 360px) and (height: 740px) {
    left: 80%;
  }
}

.direction_three {
  position: absolute;
  top: 57%;
  left: 80%;
  z-index: -1;

  @media screen and (width: 384px) and (height: 640px) {
    left: 77%;
  }
  @media screen and (width: 360px) and (height: 740px) {
    left: 72%;
  }
}

.circle-stack {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.outer-circle,
.middle-circle,
.inner-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

//   .outer-circle {
//   width: 95px;
//   height: 95px;
//   background: radial-gradient(circle, #5949CE, #140779);
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

.outer-circle {
  width: 95px;
  height: 95px;
  background: radial-gradient(circle, #5949ce, #140779);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0 0 15px 10px rgba(89, 73, 206, 0.6);
  animation: glowing 1.2s infinite alternate;
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 15px 10px rgba(89, 73, 206, 0.6);
  }
  100% {
    box-shadow: 0 0 35px 35px rgba(89, 73, 206, 1);
  }
}
.middle-circle {
  width: 67px;
  height: 67px;
  background-color: #5247a5;
  box-shadow: 0 0 15px 5px rgb(81 76 123 / 60%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.inner-circle {
  width: 41px;
  height: 41px;
  background: linear-gradient(to right, #a498ff, #725ffc);
}
.power_third-column {
  display: grid;
  grid-template-rows: repeat(3, auto);
  gap: 20px;
  padding: 5px;
  z-index: 2;
}

.power_text {
  background-color: #453a94;
  font-size: 12px;
  font-family: "Inter";
  color: #ffffff;
  text-align: center;
  height: 20px;
  width: 62px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.power_third-column :nth-child(1) {
  background-color: #00c591;
}

.power_third-column :nth-child(2) {
  background-color: #ff8c06;
}

.power_third-column :nth-child(3) {
  background-color: #8d7efd;
}

// fourth
.power_fourth-column {
  display: grid;
  grid-template-rows: repeat(3, auto);
  gap: 12px;
  padding: 0px 18px;
  margin-bottom: 15px;
  //   @media screen and (width: 360px) and (height: 740px) {
  // padding: 0px 9px;
  //   }
}

.solar_column-first {
  background: linear-gradient(to bottom, #00f2b2, #008c67);
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.power_fourth-column :nth-child(2) {
  background: linear-gradient(to bottom, #ffb179, #ff8c06);
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.power_fourth-column :nth-child(3) {
  background: linear-gradient(to bottom, #8d7efd, #453a94);
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.power_text-fourth {
  background-color: #453a94;
  color: #ffffff;
  text-align: center;
  height: 40px;

  border-radius: 5px;
}

.power_text_first {
  position: absolute;
  background: linear-gradient(to bottom, #0578ff, #48b4f1);
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  height: 20px;
  width: 62px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50px;
}
.environment {
  background: linear-gradient(90deg, #00ffbc, #038b67);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 5;

  .environment_circle {
    padding: 15px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .env_circle {
    img {
      width: 50px;
    }
  }
  .env_circle_second {
    img {
      width: 50px;
    }
  }

  .env_content {
    flex-grow: 1;
    margin-left: 15px;
    font-size: 14px;
    color: #ffffff;
    font-weight: 700;
    font-family: "Montserrat";
  }
  .env_right_image {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
  }
}
.impact {
  background: linear-gradient(90deg, #fed86a, #f1a200);
}

@media (max-width: 568px) {
  .sitepage_content {
    padding: 0 20px 10px !important;

    // .production_consumption{
    //   grid-template-columns: auto;
    // }
  }
}
@media (min-width: 1024px) {
  .production_consumption {
    grid-template-columns: repeat(4, 1fr);
  }
  .production_consumption .consumption_card {
    width: 100%;
  }
}
.inverter_list {
  padding: 0.5rem;
}
.site_inverters {
  text-align: left;
  text-transform: uppercase;
  font-size: 14px;
  color: #000;
  font-weight: 600;
  padding: 0rem 1rem;
}
</style>
